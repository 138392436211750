@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');


.time{
  color: white;
  text-align: center;
  width: 100%;
  font-size: 2000%;
}
.date{
  color: white;
  text-align: center;
  width: 100%;
  font-size: 1000%;
}

.center{
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  width: 100vw;
  height: 100vh;
}

body{
  background-color: black;
  font-family: 'Roboto Mono', monospace;
}